.ResetPasswordForm {
  &__information,
  &__errorMessage {
    text-align: center;
    margin-bottom: 12px;
  }

  &__notReceivedInformation {
    font-style: italic;
    margin: 35px 0;
  }

  &__errorMessage {
    color: var(--red);
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__createAccount {
    margin-top: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }

  &__resend {
    font-weight: 500;
    font-size: 15px;
    line-height: 17px !important;
    height: 17px !important;
  }

  &__signUp {
    text-decoration: underline !important;
  }
}
