.SearchFilters {
  display: block;
  background-color: var(--white);
  padding: 12px;

  &__content {
    &Block {
      &Header {
        margin-bottom: 0px;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.02em;
        background: linear-gradient(239.18deg, #b83af3 9.83%, #6950fb 105.7%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }

      &Row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 0;
      }

      &Label {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        color: #262628;
      }
    }
  }
}
