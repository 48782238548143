.GigCard {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 1px 1px 5px 0.5px #bab4c540;
  align-self: stretch;
  overflow: hidden;

  &__header {
    position: relative;
    width: 100%;
    height: 226px;
  }

  &__header,
  &__video {
    overflow: hidden;
  }

  &__badge {
    position: absolute;
    top: 8px;
    left: 6px;
    z-index: 9;
  }

  &__bottomContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 12px;
    left: 6px;
    right: 6px;
    z-index: 9;
    flex-wrap: nowrap;
  }

  &__creator {
    flex: 1;
  }

  &__image,
  &__placeholder {
    width: 100%;
    height: 226px;
    object-fit: cover;
    z-index: 0;
    cursor: pointer;
    background-color: var(--grey-200);
  }

  &__hoverVideo {
    height: 266px;
    border-radius: 6px 6px 0 0;
    overflow: hidden;
  }

  @media (min-width: 450px) {
    &__header,
    &__image,
    &__placeholder,
    &__hoverVideo {
      height: 276px !important;
    }
  }

  &__placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px;
    flex: 1;
  }

  &__title {
    display: -webkit-box;
    flex: 1;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #011f01;
    margin: 0;
    cursor: pointer;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }

  &__details {
    font-weight: 500;
    font-size: 10px;
    line-height: 11px;
    color: var(--primary);
    margin-top: 5px;
  }

  &__eventDate {
    font-weight: 500;
    font-size: 11px;
    line-height: 140%;
    letter-spacing: 0.32px;
    text-transform: uppercase;
    color: var(--primary-darker);
    margin-bottom: 4px;

    &--closed {
      color: var(--grey-400);
    }
  }

  &__soldOut {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: var(--red);
    margin-bottom: 3px;
  }

  &__price {
    display: flex;
    gap: 7px;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.25%;
    flex-wrap: wrap;
    margin-bottom: 6px;
  }

  &__currentPrince {
    background: linear-gradient(239.18deg, #b83af3 9.83%, #6950fb 105.7%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__normalPrice {
    color: var(--grey-400);
    text-decoration: line-through;
  }

  &__location {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #4f4d4d;
  }
}
