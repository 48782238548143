.Checkbox {
  $base: &;
  display: flex;
  align-items: center;

  &--small {
    gap: 5px;

    #{$base}__label {
      font-size: 14px;
      line-height: 18px;
    }

    #{$base}__markContainer {
      width: 16px;
      height: 16px;
      line-height: 16px;
      border-radius: 3px;
      border-width: 1px;
    }
  }

  &--medium {
    gap: 7px;

    #{$base}__label {
      font-size: 15px;
      line-height: 19px;
    }

    #{$base}__markContainer {
      width: 22px;
      height: 22px;
      line-height: 22px;
      border-radius: 7px;
    }
  }

  &--labelFirst {
    flex-direction: row-reverse;
  }

  &__markContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--grey-100);
    border: 2px solid var(--primary);
    flex-shrink: 0;
    cursor: pointer;
  }

  &__label {
    max-width: fit-content;
    cursor: pointer;
  }
}
