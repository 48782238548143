.HyperLocal {
  display: flex;
  gap: 7px;

  &__inputWrapper {
    display: flex;
    position: relative;
    align-items: center;
    height: 50px;
    padding: 0 12px;
    line-height: 50px;
    background: transparent;
    flex: 1;
    gap: 7px;
  }

  &__icon {
    min-width: 18px;
    width: 18px;
    height: 18px;
  }

  &__input {
    width: 100%;
    border: none;
    background-color: transparent;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;

    &::placeholder {
      color: rgba(60, 60, 60, 1);
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
    }

    &:focus {
      outline: none;
    }
  }

  &__clear {
    font-weight: 100 !important;
    color: var(--silver);
    font-size: 22px;
    line-height: 22px;
    width: 22px !important;
    height: 22px !important;
    max-height: 22px !important;

    &Icon {
      width: 14px;
      height: 14px;
    }
  }

  &__myLocationButton,
  &__locationItem {
    width: 100% !important;
    text-align: left !important;
    height: auto !important;
    min-height: auto !important;
    font-weight: 400;
  }

  &__myLocationButton {
    font-size: 16px !important;
    line-height: 20px !important;
    border: 1px solid var(--purple-200);
    padding: 7px !important;
    border-radius: 7px !important;
    background-color: #f9f5ff !important;
  }

  &__locationItem {
    line-height: 22px !important;
    border-top: 1px solid var(--grey-100) !important;
    padding: 4px 2px !important;

    &:first-child {
      border-top: none !important;
    }
  }

  &__panel {
    display: block;
    position: absolute;
    width: 100%;
    padding: 12px;
    background: var(--white);
    top: 55px;
    right: 0;
    border-radius: 6px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    z-index: 5;
  }

  &__suggestions {
    margin-top: 10px;
  }
}
