.MediaPlayer {
  $base: &;
  display: block;
  position: relative;
  width: 100%;
  max-width: 100vw;
  height: 100%;

  @media (min-width: 800px) {
    max-width: calc(100vw - 411px);
  }

  @media (min-width: 1100px) {
    max-width: calc(100vw - 548px);
  }

  &__pageIndicator {
    position: absolute;
    width: 95%;
    height: 5px;
    left: 50%;
    transform: translateX(-50%);
    top: 12px;
    z-index: 99;
  }

  &__pageInfo {
    display: flex;
    position: absolute;
    flex-direction: row;
    align-items: center;
    padding: 5px 8px;
    gap: 4px;
    width: 72px;
    height: 40px;
    right: 4px;
    top: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.02em;
    color: var(--neutral-500);
    background: rgba(223, 211, 255, 0.5);
    backdrop-filter: blur(5px);
    color: rgb(48, 47, 47);
    border-radius: 500px;
    z-index: 9999;

    @media (min-width: 950px) {
      top: 40px;
      right: 50px;
    }
  }

  .slick-slider {
    width: 100%;
    height: calc(var(--viewport-height) - 55px) !important;
    margin: 0 auto;

    @media (min-width: 800px) {
      height: calc(var(--viewport-height) - 80px) !important;
    }
  }

  // .slick-list {
  //     width: 100%;
  // }

  // .slick-track {
  //     // width: 100% !important;
  // }

  // .slick-slide {
  //     background-color: purple;
  // }

  .slick-dots {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 25px;
    height: 40px;

    button {
      width: 8px !important;
      height: 8px !important;
      background: #ffffff;
      opacity: 0.5;
      border-radius: 50px;

      &::before {
        content: '';
      }
    }

    .slick-active {
      button {
        width: 8px !important;
        height: 8px !important;
        opacity: 1;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
      }
    }
  }

  &__imageWrapper {
    display: flex !important;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    height: calc(var(--viewport-height) - 55px) !important;
    overflow: hidden;

    @media (min-width: 800px) {
      height: calc(var(--viewport-height) - 80px) !important;
    }
  }

  &__imageContainer {
    display: inline-block;
    height: 100%;
    margin: 0 auto !important;

    img {
      width: auto;
      height: 100%;
      object-fit: contain;
      margin: 0 auto;
    }
  }

  &__bottom {
    position: absolute;
    width: 100%;
    min-height: 115px;
    bottom: 0;
    z-index: 90;
  }

  &__embed {
    width: 100%;
    padding: 0 32px;
    background: linear-gradient(
      180deg,
      rgba(2, 2, 2, 0) 0%,
      rgba(2, 2, 2, 0.6) 80.4%
    );

    &Container {
      width: 100%;
      max-width: 900px;
      margin: 0 auto;
    }
  }
}
