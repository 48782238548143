.UserCard {
  width: 100%;

  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  &__image {
    margin-right: 9px;
  }

  &__userName,
  &__fullName {
    letter-spacing: 0.32px;
    word-break: break-word;
  }

  &__userName {
    color: #1e2432;
    font-size: 20px;
    line-height: 23px;
  }

  &__fullName {
    color: #999;
    font-size: 16px;
    line-height: 18px;
  }
}
