.EventLocation {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;

  &__location {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    flex: 1;
    color: #080a11;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-decoration: none;
    padding: 8px;
    border-radius: 12px;
    border: 1px solid var(--neutral-200);
    cursor: pointer;
  }

  &__distance {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--neutral-500);
  }

  &__map {
    width: 100%;
    height: 176px;
    border-radius: 8px;
    overflow: hidden;
  }
}
