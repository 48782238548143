.VideoMessage {
  $base: &;
  display: flex;
  margin-top: 15px;
  gap: 7px;
  color: #ffffff !important;
  cursor: pointer;

  &--own {
    justify-content: flex-end;

    #{$base}__bubble {
      border-radius: 20px 0px 20px 20px;
    }
  }

  &__bubble {
    position: relative;
    background: var(--grey-300);
    order: 2;
    max-width: 250px;
    flex: 1;
    border-radius: 0px 20px 20px 20px;
    height: 320px;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: 12px;
    width: 100%;
    bottom: 0;
    gap: 8px;
    background: linear-gradient(
      180deg,
      rgba(2, 2, 2, 0) 0%,
      rgba(2, 2, 2, 0.4) 47.4%
    );
  }

  &__author {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__userImage {
    flex-shrink: 0;
  }

  &__userName {
    font-size: 10px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.2px;
  }

  &__title {
    text-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.5);
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.0175rem;
  }

  &__time {
    text-align: right;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1.25rem;
    letter-spacing: 0.02rem;
  }
}
