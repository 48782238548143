.CompleteProfileForm {
  &__errorMessage {
    text-align: center;
    color: var(--red);
    margin-bottom: 12px;
  }

  &__content {
    padding: 22px 0;
  }

  &__uploaderWrapper {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    padding: 27px 0;
  }

  &__uploader > div {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
  }

  &__profileImage {
    background: var(--purple-200) !important;
    margin: 0 auto;
  }

  &__hooplaIcon {
    svg {
      fill: #cfbaf5 !important;
    }
  }

  &__uploaderBtnText {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: var(--grey-400);
    text-align: center;
    margin-bottom: 17px;
  }

  &__later {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.32px;
    color: var(--primary);
    text-align: center;
    width: auto !important;
    min-width: auto !important;
    padding: 0 !important;
    margin-top: 17px;
    text-decoration: underline;
  }

  &__buttons {
    display: flex;
    gap: 12px;
  }

  &__back,
  &__next {
    flex: 1 !important;
    min-width: 1px !important;
  }

  &__createAccount {
    margin-top: 17px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }
}
