.Loader {
  $base: &;
  display: flex;
  align-items: center;
  justify-content: center;

  &--light {
    &__logo {
      @for $x from 1 through 3 {
        .star#{$x} {
          fill: #fff;
        }
      }
    }

    .path {
      stroke: #fff !important;
    }
  }

  &--fixed {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(#000, 0.2);
    z-index: 2000;
  }

  .spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    width: 120px;
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }

  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.3s ease-in-out infinite;
    stroke-linecap: round;
    stroke: #7738e3;
  }

  #{$base}__logo {
    @for $x from 1 through 3 {
      .star#{$x} {
        fill: #7738e3;
      }
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  // @keyframes color {
  //   100%,
  //   0% {
  //     stroke: var();
  //   }
  //   40% {
  //     stroke: $blue;
  //   }
  //   66% {
  //     stroke: $green;
  //   }
  //   80%,
  //   90% {
  //     stroke: $yellow;
  //   }
  // }
}
