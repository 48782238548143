.ViewsBadge {
  $base: &;
  display: flex;
  align-items: center;
  gap: 7px;

  svg {
    width: 13px;
    height: 13px;
  }

  &--dark {
    svg {
      path {
        fill: var(--neutral-500) !important;
      }
    }

    #{$base}__count {
      color: var(--neutral-500) !important;
    }
  }

  &--large {
    font-weight: 800 !important;
    font-size: 16px !important;
    line-height: 14px !important;

    svg {
      width: 22px;
    }
  }

  &--reverse {
    flex-direction: row-reverse;
  }

  &__count {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--white);
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }
}
