.Chat {
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-color: #fff;
  color: var(--grey-400);
  font-weight: 500;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;

  @media (min-width: 470px) {
    width: 400px;
    max-width: 400px;
  }

  &__dateRow {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__date {
    display: block;
    background: #b6b6b6;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.16px;
    color: #ffffff;
    border-radius: 16px;
    padding: 4px 12px;
    margin: 15px 0 0;
  }

  &--expanded {
    border-radius: 7px 7px 0 0;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  }

  &__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--grey-100);
    line-height: 48px;
    height: 48px;
    padding: 0 12px;
  }

  &__userImage {
    cursor: pointer;
  }

  &__userName {
    color: #000;
    flex: 1;
    margin-left: 12px;
    line-height: 48px;
    height: 48px;
    font-weight: 600;
    cursor: pointer;
  }

  &__close {
    margin-left: auto;
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: calc(var(--viewport-height) - 48px);

    @media (min-width: 800px) {
      height: 60vh;
    }
  }

  &__wrapper {
    display: flex;
    height: 100%;
  }

  &__loader {
    margin: 0 auto;
  }

  &__loadMoreButton {
    display: block !important;
    width: 100% !important;
    align-self: center;
  }

  &__noMessages {
    margin: 50% 40px 0;
    text-align: center;
    color: var(--grey-400);
    transform: translateY(-50%);
    font-size: 13px;
  }

  &__messages {
    padding: 12px;
  }

  &__messageForm {
    padding: 12px;
    border-top: 1px solid var(--grey-100);
  }
}
