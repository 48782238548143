.ClipCard {
  max-width: 300px;

  &__container {
    width: 100%;
    height: 190px;
    box-shadow: 2px 1px 10px 3px rgba(121, 117, 128, 0.25);
    border-radius: 12px;
    cursor: pointer;

    @media (min-width: 450px) {
      height: 242px;
    }
  }

  &__hoverVideo,
  &__placeholder {
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 12px;
    overflow: hidden;
  }

  &__thumbnail {
    height: 190px;
    width: 100%;
    border-radius: 12px;
    object-fit: cover;

    @media (min-width: 450px) {
      height: 242px;
    }
  }

  &__placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }

  &__bottom {
    display: flex;
    gap: 7px;
    margin-top: 7px;
  }

  &__link {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-decoration: none;
  }

  &__views {
    margin-top: 1px;
  }
}
