.DropdownMenu {
  $base: &;
  position: relative;

  &__list {
    &--to-right {
      right: auto;
      left: 0;
    }

    &--fullScreen {
      width: 100%;
      bottom: 0;

      @media (min-width: 576px) {
        width: auto;
        bottom: unset;
      }
    }
  }

  &__subList {
    height: 0;
    overflow: hidden;

    &--display {
      padding: 4px 2px;
      border-bottom: 1px solid var(--neutral-100);
      height: auto;
    }

    #{$base}__item {
      height: auto;
    }
  }

  &__header {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.5;
    color: #161717;
    padding: 8px 10px 10px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    padding: 0 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 40px;
    letter-spacing: 0.2px;
    color: #52575c;
    transition: background-color 200ms;
    cursor: pointer;
    user-select: none;
    background: #fff;
    border: none;
    width: 100%;

    &:hover,
    &--selected {
      background-color: #f2f4f8;
    }

    &:disabled {
      pointer-events: none;
    }

    &--fullScreen {
      padding: 0 20px;
      color: #161717;
      min-height: 56px;

      @media (min-width: 576px) {
        color: #52575c;
        padding: 0 12px;
        min-height: unset;
      }
    }
  }

  &__item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;

    svg {
      height: 70%;
      width: auto;
    }
  }

  &__item-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__button {
    width: 24px;
    height: 24px;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--active,
    &:hover {
      background: #e8f2f7;
    }

    svg {
      fill: #747487;
    }
    &--dark-gray {
      svg {
        fill: #747487;
        stroke: transparent;
      }
    }
  }
}
