.SocialAuthentication {
  &__label {
    color: var(--grey-400);
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    max-width: 337px;
    gap: 10px;
    margin: 7px auto 20px;

    &--iconButtons {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    &--withLabel {
      justify-content: flex-start !important;
      margin-left: 0;
    }
  }
}
