.Comment {
  display: flex;
  position: relative;
  align-items: flex-start;
  padding: 0;

  &--withReplies {
    &::before {
      content: '';
      position: absolute;
      top: 46px;
      left: 19px;
      bottom: 0;
      width: 1px;
      border-radius: 0 500px 500px 0;
      background-color: var(--primary-100);
    }

    &:hover {
      &::before {
        background-color: var(--primary);
      }
    }
  }

  &__avatarLink {
    flex-grow: 0;
  }

  &__avatar {
    flex-shrink: 0;
  }

  &__body {
    width: 100%;
    margin-left: 10px;
  }

  &__author,
  &__text {
    font-size: 14px;
    line-height: 16px;
    margin: 0;
  }

  &__text {
    margin-bottom: 7px;
  }

  &__author {
    display: inline-block;
    color: var(--primary);
    margin-bottom: 6px;
    text-decoration: none;
    &:hover {
      color: var(--primary);
    }
  }

  &__actions {
    display: flex;
    margin-bottom: 7px;
  }

  &__separator {
    margin: 0 7px;
    color: var(--grey-300);
  }

  &__reply {
    background-color: transparent;
    color: #7d7d7f;
    padding: 0;
    font-size: 14px;
    transition: color 300ms ease-in-out;

    &:hover {
      color: var(--primary);
    }
  }

  &__showAllReplies {
    font-size: 13px !important;
    line-height: 14px !important;
    height: 14px !important;
  }
}
