.Dropdown {
  $base: &;
  position: relative;

  &__menu {
    position: absolute;
    z-index: 1199;
    top: calc(100% + 4px);
    left: 0;
    padding: 4px 0;
    font-family: 'Rubik', sans-serif;
    background-color: #fff;
    box-shadow: 0px 8px 20px rgba(73, 81, 92, 0.24);
    border-radius: 4px;
    overflow: hidden;

    &--fullScreen {
      position: fixed;
      z-index: 1199;
      width: 100%;
      top: auto;
      bottom: 0;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;

      @media (min-width: 576px) {
        position: absolute;
        z-index: 5;
        width: auto;
        top: calc(100% + 4px);
        bottom: unset;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    &--portal {
      z-index: 1199;
      position: fixed !important;
    }

    &--right {
      left: auto;
      right: 0;
    }

    &--left {
      transform: translateX(-100%);
      top: 0;
    }

    &--top {
      top: auto;
      bottom: calc(100% + 4px);
    }

    &--center {
      left: 50%;
      transform: translate3d(-50%, 0, 0);
    }
  }

  &--color-picker {
    .Dropdown__menu {
      padding: 0;
    }
  }

  &__backdrop {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 1199;
    position: fixed;

    @media (min-width: 576px) {
      display: none;
    }
  }
}
