.MediaPager {
  gap: 7px;

  &__item {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &__figure,
  &__progress {
    height: 100%;
    border-radius: 8px;
    background: #a4a4a460;
    transition: background-color width 0.25s;

    &--current {
      background: var(--neutral-100);
    }
  }

  &__figure {
    width: 100%;
  }

  &__progress {
    position: absolute;
    left: 0;
    top: 0;
    background: var(--primary-gradient);
  }
}
