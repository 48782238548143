.Attendees {
  display: flex;
  position: relative;

  &__attendee,
  &__plus {
    display: block;
    position: relative;
    width: 36px;
    height: 36px;
    margin-left: -10px;
    object-fit: cover;
  }

  &__attendee:first-child {
    margin: 0;
  }

  &__plus {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: var(--primary);
    background-color: var(--primary-light);
    border-radius: 50%;
    width: 48px;
    height: 48px;
    cursor: pointer;
  }
}
