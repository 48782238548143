.EmailAuthButton {
  $base: &;
  display: block;
  width: 100%;
  border-radius: 4px !important;
  background-color: var(--grey-100) !important;
  height: 32px !important;
  line-height: 32px !important;
  padding: 0 10px !important;

  &--icon {
    width: 33px !important;
    max-width: 33px !important;
    min-width: 33px !important;

    #{$base}__wrapper {
      justify-content: center;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__label {
    margin: 0 auto;
    font-weight: 600;
    font-size: 15px;
  }

  &__icon {
    min-width: 26px;
    height: auto;
  }
}
