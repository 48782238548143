.LikeButton {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  padding: 2px;
  color: #7d7d7f;
  transition: background-color 0.25s, color 0.25s, border-color 0.25s;
  text-decoration: none;

  &:hover,
  &--liked {
    path {
      fill: var(--secondary) !important;
    }
  }

  &--liked {
    color: var(--secondary) !important;
    font-weight: 700;

    svg {
      animation: beat 200ms ease-in-out;
    }
  }

  svg {
    width: 11px;
    height: 11px;
    margin-right: 4px;

    path {
      transition: fill 0.25s;
      fill: #7d7d7f;
    }
  }

  @keyframes beat {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.75);
    }
    100% {
      transform: scale(1);
    }
  }
}
