.EventDetails {
  $base: &;

  &__pt-12 {
    padding-top: 12px;
  }

  &__pt-24 {
    padding-top: 24px;
  }

  &__pb-12 {
    padding-bottom: 12px;
  }

  &__pb-24 {
    padding-bottom: 24px;
  }

  &__wrapper {
    padding: 10px 30px 30px;
  }

  &__goBackButtonWrapper {
    padding: 0 30px;
  }

  &__goBackButton {
    padding-left: 10px;
    padding-right: 17px;
  }

  &__separator {
    border-bottom: 1px solid var(--neutral-300);
    margin-top: 12px;
    padding: 12px 0 24px;
  }

  &__eventDate {
    font-weight: 600;
    font-size: 17px;
    line-height: 140%;
    letter-spacing: 0.32px;
    color: var(--primary-darker);

    &--closed {
      color: var(--grey-400);
    }

    @media (min-width: 750px) {
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
    }
  }

  &__eventStartEndDate {
    display: flex;
    align-items: center;
    gap: 12px;

    span {
      color: #000;
    }

    &__end {
      margin-right: 13px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #080a11;
    margin-bottom: 12px;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 11px;
    margin-top: 12px;
  }

  &__event {
    &SoldOut {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;

      svg path {
        fill: var(--red);
      }
    }

    &Closed {
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      color: var(--neutral-500);
    }
  }

  &__price {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__currentPrice {
    font-weight: 500;
    font-size: 18px;
    line-height: 23.4px;
  }

  &__modalButtons {
    display: flex;
    margin: 20px auto !important;

    .Button {
      border-radius: 70px;

      &:first-child {
        margin-right: 7px;
      }
    }
  }

  &__headers {
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: var(--primary);
    margin-bottom: 12px;

    &--location {
      margin-bottom: 0px;
    }
  }

  &__policyHeader {
    color: var(--red);
  }

  &__attendingHeader {
    font-size: 18px;
    font-weight: 500;
    line-height: 23.4px;
    margin-bottom: 12px;
  }

  &__actions {
    display: flex;
    gap: 16px;
    padding: 24px 0 12px;
  }

  &__chatAction {
    flex: 1;
  }

  &__bookAction {
    flex: 1;
    min-width: 1px !important;
  }

  &__externalLinkIcon {
    path {
      stroke: #fff;
    }
  }

  &__score {
    display: flex;
    align-items: center;

    .star {
      fill: var(--primary);
      margin-right: 10.44px;
    }

    svg {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }

  &__location {
    margin-top: 12px;
  }

  &__availability {
    margin-top: 16px;
  }

  &__infoContent {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #080a11;
    white-space: pre-line;
    word-break: break-word;
  }

  &__showMore {
    color: var(--primary);
    display: block;
    margin: 0 auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-decoration-line: underline;
    text-align: center;
    margin-top: 12px;
    cursor: pointer;
  }

  &__hashtags {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
  }

  &__hashtag {
    font-size: 12px;
    line-height: 14px;
    color: var(--primary);
    border: 1px solid var(--primary);
    padding: 5px;
    font-weight: normal;
    text-decoration: none;
    border-radius: 3px;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }
  }

  &__tabs {
    padding-top: 12px;
  }

  &__tabList {
    display: flex;
    border-bottom: 1px solid var(--neutral-400);
  }

  &__tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    position: relative;
    color: var(--neutral-400);
    border-bottom: 1px solid transparent;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    padding-bottom: 2px;
    bottom: -1px;
    transition: background 0.5s ease, border-color 0.5s ease,
      -webkit-text-fill-color 0.2s ease;
    cursor: pointer !important;

    #stop1,
    #stop1 {
      transition: stop-color 0.4s ease;
    }

    &Label {
      height: 21px;
      line-height: 21px;
    }
  }

  .react-tabs__tab--selected {
    background: var(--primary-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-color: var(--primary);

    #stop1 {
      stop-color: #b83af3;
    }

    #stop2 {
      stop-color: #6950fb;
    }
  }

  &__clips {
    display: flex;
    flex-wrap: wrap;
    row-gap: 24px;
    column-gap: 9px;

    #{$base}__clip {
      flex: 0 0 48.1%;

      @media (min-width: 500px) {
        flex: 0 0 31.5%;
      }

      @media (min-width: 600px) {
        flex: 0 0 23.7%;
      }

      @media (min-width: 800px) {
        flex: 0 0 48.1%;
      }

      @media (min-width: 1100px) {
        flex: 0 0 32%;
      }
    }
  }

  &__rating {
    display: flex;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 12px;
    gap: 8px;
  }

  &__noContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 16px;
    padding: 24px 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--neutral-400);
  }

  &__policyLink {
    display: inline-block;
    color: var(--primary);
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-decoration-line: underline;
    margin-top: 12px;
  }
}
