.BookingAttachment {
  $base: &;
  margin-top: 15px;
  padding: 10px;
  border-radius: 10px;
  color: #000;
  flex: 1;

  &--awaiting {
    background-color: var(--grey-200);

    #{$base}__status {
      color: rgb(255, 188, 64);
    }
  }

  &--confirmed {
    background: var(--primary-gradient);
    color: var(--white) !important;

    #{$base}__status {
      color: rgb(29, 206, 0);
    }
  }

  &--providerDeclined,
  &--customerCancelled,
  &--auto-declined {
    background-color: var(--grey-200);

    #{$base}__status {
      color: rgb(255, 0, 0);
    }
  }

  &--complete {
    background-color: var(--primary-100);

    #{$base}__status {
      color: rgb(169, 145, 239);
    }
  }

  &__status {
    font-size: 15px;
    font-weight: 600;
  }

  &__body {
    display: flex;
    gap: 12px;
    padding: 12px 0;
    font-size: 14px;
  }

  &__details {
    flex: 2;
  }

  &__video {
    flex: 1 0 5px;

    &Wrapper {
      position: relative;
      width: 100%;
      height: 160px;
      filter: drop-shadow(1px 1px 6px rgba(186, 180, 197, 0.35));
    }

    &User {
      position: relative;
    }

    &Views {
      position: absolute;
      bottom: 12px;
      right: 12px;
      z-index: 9;
    }

    &Thumbnail,
    &Placeholder {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      object-fit: cover;
      z-index: 0;
    }

    &Placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
    }
  }

  &__row {
    padding: 1px 0;
  }

  &__eventName {
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 15px;
  }

  &__eventPrice {
    font-weight: 600;
    margin-top: 12px;
    font-size: 15px;
  }

  &__customer {
    display: flex;
    gap: 7px;
    font-size: 15px;

    &Name {
      font-size: 13px;
      opacity: 0.8;
    }
  }
}
