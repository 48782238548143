.ShareContentModal {
  padding: 12px;

  &__title {
    color: #000000;
    font-weight: 600;
    font-size: 19px;
    line-height: 22px;
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
    padding: 20px 30px;
  }

  &__copyToClipboard {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 30px;

    @media (min-width: 400px) {
      flex-direction: row;
      padding: 0 30px;
    }
  }

  &__input {
    flex: 1 !important;
  }

  &__copy {
    min-width: 20px !important;
    margin-top: -12px;

    @media (min-width: 400px) {
      margin-top: 0;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
}
