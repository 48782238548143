.TextField {
  position: relative;
  padding-bottom: 18px;
  &.password {
    .TextField__input {
      padding-right: 65px !important;
    }
  }

  &__inputWrapper {
    position: relative;
  }

  &__input {
    border: 1px solid var(--primary);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 0 14px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    color: #000;
    width: 100%;

    @media (min-width: 800px) {
      height: 46px;
      line-height: 46px;
    }

    &::placeholder {
      color: var(--dark);
    }

    &:focus-visible {
      outline-color: var(--primary);
    }

    &--textarea {
      height: auto;
      line-height: inherit;
      padding: 17px 14px;
    }
  }

  &__show-more {
    position: absolute;
    background-color: transparent;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: var(--primary);
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
  &__helperText {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 12px;
    color: #555;
  }
  &__error {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 12px;
    color: var(--red);
  }
  &__label {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: var(--primary);
  }

  &--insetLabel {
    position: relative;
    .TextField__input {
      padding-top: 24px !important;
      padding-bottom: 8px !important;
    }
    .TextField__label {
      font-size: 12px;
      line-height: 14px;
      color: #585858;
      position: absolute;
      top: 8px;
      font-weight: normal;
      left: 18px;
    }
  }

  &__count {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #585858;
  }
}
