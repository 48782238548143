.AttendeesModal {
  display: flex;
  flex-direction: column;

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--primary);
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 7px;
    padding: 20px 20px 12px;
    border-bottom: 1px solid var(--grey-200);
  }

  &__close {
    width: 28px !important;
    height: 28px !important;
    min-width: 28px !important;
    min-height: 28px !important;
    line-height: 28px !important;
  }

  &__closeIcon {
    width: 15px;
    height: 15px;
  }

  &__content {
    display: flex;
    border-top: 1px solid var(--grey--200);
    border-bottom: 1px solid var(--grey--200);
    max-height: calc(80vh - 180px);
  }

  &__attendeesList {
    flex: 1;
    height: 100%;
    padding: 20px;
  }

  &__attendee {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__followButton {
    margin-left: auto !important;
  }
}
