.VideoActions {
  $base: &;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 50px;

  &__actionButtonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 11px;
    color: var(--white);
    margin-bottom: 10px;

    span {
      margin-top: 5px;
      color: var(--neutral-400);
    }
  }

  &__actionButton {
    position: relative;
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
    background: rgba(223, 211, 255, 0.5);
    backdrop-filter: blur(5px);
    color: rgb(48, 47, 47);
    border-radius: 500px;

    svg {
      width: 12px;
      height: auto;
    }

    &--up {
      transform: rotate(-180deg);
    }

    @media (min-width: 800px) {
      width: 50px !important;
      height: 50px !important;
      min-width: 50px !important;
      min-height: 50px !important;

      svg {
        width: 22px;
        height: auto;
      }
    }
  }

  &__upButton {
    margin-bottom: 50px;
  }

  &__volumeControls {
    position: relative;

    &:hover {
      #{$base}__volumeRange {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__volumeRange {
    display: flex;
    position: absolute;
    justify-content: center;
    border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 12px 15px;
    opacity: 0;
    transition: opacity 200ms;
    bottom: 29px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    visibility: hidden;

    @media (min-width: 800px) {
      bottom: 49px !important;
    }
  }
}
