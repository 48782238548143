.Button {
  $base: &;
  background-color: transparent;
  font-weight: 600;
  font-size: 15px;
  height: 35px;
  padding: 0 17px;
  transition: background 0.25s, color 0.25s, border-color 0.25s, opacity 0.25s,
    border-radius 0.25s;
  border: 1px solid transparent;
  text-decoration: none;

  &__iconRight {
    flex-direction: row-reverse;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
  }

  @media (min-width: 800px) {
    font-size: 17px;
    height: 48px !important;
  }

  &--withIconAndLabel {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__label {
    &--icon {
      margin-left: 10px;
    }
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      opacity: 0.3;
    }
  }

  //border radius
  &--pill {
    border-radius: 61px !important;
  }

  &--rounded {
    border-radius: 8px;
  }

  //  Sizes
  &--auto {
    width: auto;
  }

  &--sm {
    width: auto;
    min-width: 155px;
    height: 34px;
  }

  &--md {
    width: auto;
    min-width: 242px;
  }

  &--fullWidth {
    width: 100% !important;
  }

  // Variants
  &--solid {
    border: none !important;
    color: var(--white) !important;
  }

  &--outline {
    background-color: transparent !important;
    border-width: 1px !important;
    border-style: solid !important;
  }

  &--text {
    color: var(--secondary);
    padding: 0 2px !important;
    background-color: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    width: auto !important;
    min-width: auto !important;

    &:hover {
      color: var(--primay);
      background-color: transparent;
    }
  }

  &--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-color: transparent !important;
    padding: 0;
    width: 42px !important;
    height: 42px !important;
    min-width: 42px !important;
    min-height: 42px !important;
    border-radius: 50px;

    &:hover {
      opacity: 0.6;
    }
  }

  // Colors
  &--action {
    background: linear-gradient(239.18deg, #b83af3 9.83%, #6950fb 105.7%);
    color: #fff !important;

    &:hover {
      background: linear-gradient(
        239.18deg,
        darken(#b83af3, 10%) 9.83%,
        darken(#6950fb, 20%) 105.7%
      );
    }
  }

  &--primary {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--primary);

    &:hover {
      background-color: darken(#7741de, 10%);
      border-color: darken(#7741de, 10%);
      color: darken(#7741de, 10%);
    }
  }

  &--primaryLight {
    background-color: var(--primary-50);
    border-color: var(--primary-50);
    color: var(--primary) !important;

    &:hover {
      background-color: darken(#f9f5ff, 5%);
      border-color: darken(#f9f5ff, 5%);
    }
  }

  &--secondary {
    background-color: var(--secondary);
    border-color: var(--secondary);
    color: var(--secondary);

    &:hover {
      background-color: darken(#ea3ad3, 10%);
      border-color: darken(#ea3ad3, 10%);
      color: darken(#ea3ad3, 10%);
    }
  }

  &--danger {
    background-color: var(--red);
    border-color: var(--red);
    color: var(--red);

    &:hover {
      background-color: darken(#ff0000, 10%);
      border-color: darken(#ff0000, 10%);
      color: darken(#ff0000, 10%);
    }
  }

  &--light {
    background-color: var(--grey-300);
    color: #000 !important;
    border-color: var(--white);

    &:hover {
      background-color: var(--grey-200);
      color: darken(#7741de, 50%);
    }
  }

  &--center {
    display: block !important;
    margin: 0 auto;
  }
}
