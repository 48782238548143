.PlaceHolderCard {
  width: 100%;
  background-color: #f7f7f8;
  border-radius: 12px;
  display: flex;
  padding: 24px 0px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  &--light {
    background-color: transparent !important;
  }

  &__label {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.32px;
  }
}
