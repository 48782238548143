.Header {
  $base: &;
  width: 100%;
  background: var(--white);
  box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.1);

  &__wrapper {
    width: 100%;
    max-width: var(--wrapper-width);
    margin: 0 auto;
  }

  &__top {
    display: flex;
    align-items: center;
    height: 55px;
    padding: 0 12px;

    @media (min-width: 800px) {
      gap: 35px;
      height: 80px;
    }

    @media (min-width: 1364px) {
      padding: 7px 0;
    }
  }

  &__userMenu {
    .DropdownMenu__list {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      padding: 21px !important;
      gap: 8px;
    }

    .DropdownMenu__item {
      display: flex;
      min-width: 213px;
      line-height: 40px;
      padding: 8px 12px;
      align-items: center;
      gap: 10px;
      border-radius: 20px;
      font-size: 14px;
      font-weight: 500;

      &:hover {
        background: #e5def2;
      }
    }

    .DropdownMenu__item-icon {
      color: var(--primary);

      svg {
        width: 100%;
      }
    }
  }

  &__userMenuTrigger {
    border-radius: 12px;
    padding: 3px;
    color: #333;
  }

  .Dropdown--opened {
    #{$base}__userMenuTrigger {
      background-color: var(--primary-50);
      color: var(--primary);
    }
  }

  &__userCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
  }

  &__userFollowers {
    color: var(--primary);
    font-size: 14px;
  }

  &__logoContainer {
    display: block;
  }

  &__logo {
    display: block;
    width: 110px;
    min-width: 110px;
    max-width: 110px;
    height: auto;

    &--small {
      width: 46px;
      min-width: 46px;
      max-width: 46px;
    }
  }

  &__rightSection {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 15px;
  }

  &__whatIsHoopla {
    display: none !important;
    line-height: 32px !important;
    max-height: 32px !important;
    color: #000 !important;

    @media (min-width: 640px) {
      display: flex !important;
    }
  }

  &__auth {
    padding: 0px 10px !important;
  }

  &__user {
    cursor: pointer !important;
  }

  &__middle {
    width: 80%;
  }

  &__bottomSection {
    width: 100%;
    margin-top: 1em;
  }

  &__children {
    width: 100%;
    margin-top: 3em;
    margin-bottom: 1.5em;
  }

  &__hideOnSmallDevices,
  &Nav__hideOnSmallDevices {
    display: none;

    @media (min-width: 700px) {
      display: block;
    }
  }

  &__showOnSmallDevices,
  &Nav__showOnSmallDevices {
    display: block;

    @media (min-width: 700px) {
      display: none;
    }
  }

  //Navigation Bar
  &Nav {
    ul {
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
      list-style: none;
      gap: 10px;
    }

    .Button--text .Button__label {
      color: #000 !important;
    }

    &__floatingPanels {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: stretch;
      position: absolute;
      top: 40px;
      left: 50%;
      width: 100vw;
      max-width: 400px;
      padding: 12px;
      background: var(--white);
      box-shadow: 2px 2px 10px 3px rgba(146, 143, 152, 0.15);
      border-radius: 12px;
      opacity: 0;
      transition: opacity 0.2s ease-in-out, top 0.2s ease-in-out;
      transform: translateX(-50%);
      z-index: 40;

      &--show {
        top: 70px;
        opacity: 1;
      }
    }
  }
}
