.Avatar {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 106px;
  max-height: 106px;
  border-radius: 50%;
  background: var(--primary-gradient);

  &--border {
    border: 2px solid #eee;
    padding: 2px;
  }

  &--xs {
    width: 20px;
    min-width: 20px;
    height: 20px;

    span {
      font-size: 16px;
      line-height: 16px;
    }
  }

  &--small {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;

    span {
      font-size: 25px;
      line-height: 21px;
    }
  }

  &--medium {
    width: 48px;
    min-width: 48px;
    height: 48px;
    min-height: 48px;

    span {
      font-size: 35px;
      line-height: 27px;
    }
  }

  &--large {
    width: 106px;
    min-width: 106px;
    height: 106px;
    min-height: 106px;

    span {
      font-size: 70px;
      line-height: 60px;
    }
  }

  &--xl {
    width: 250px;
    height: 250px;
    min-width: 250px;
    min-height: 250px;
    span {
      font-size: 165px;
      line-height: 60px;
    }
  }

  &--shadow {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  span {
    position: absolute;
    font-weight: 900;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #ffffff;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__badge {
    position: absolute;
    bottom: -7px;
    left: 50%;
    margin-left: -14.5px;
  }
}
