.EventBadge {
  $base: &;
  display: inline-flex !important;
  align-items: center;
  border-radius: 100px;
  height: 24px;
  padding-right: 8px;
  padding-left: 3px;
  flex-grow: 0;

  &--shadowed {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  &--event,
  &--private {
    background: var(--primary-gradient);
  }

  &--private {
    padding-left: 8px;
  }

  &--soldOut,
  &--passed {
    padding-right: 8px;
    padding-left: 8px;
  }

  &--soldOut {
    background: var(--red);
  }

  &--passed {
    background: var(--primary-darker);
  }

  &--recurrent {
    background: var(--primary);
  }

  &--medium {
    padding: 0 12px !important;
    height: 31px;

    #{$base}__label {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
    }
  }

  &__logo {
    @for $x from 1 through 3 {
      .star#{$x} {
        fill: #fff;
      }
    }
  }

  .path {
    stroke: #fff !important;
  }

  svg {
    max-width: 25px;
  }

  &__label {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
  }
}
