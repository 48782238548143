@import './reset.scss';
// $enable-negative-margins: true;
@import '~bootstrap/scss/bootstrap-utilities';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/grid';

:root {
  --primary: #7741de;
  --primary-50: #f9f5ff;
  --primary-100: #f2eaff;
  --primary-400: #cab0fc;
  --primary-darker: #3c00ac;
  --primary-light: #cab0fc;
  --primary-lighter: #f9f5ff;
  --secondary: #ea3ad3;
  --white: #fff;
  --purple-200: #f0eafc;
  --purple-800: #4a4c84;
  --purple-900: #303273;
  --grey-100: #f2f3f6;
  --grey-200: #f2f2f2;
  --grey-300: #f7f7f8;
  --grey-400: #80848f;
  --silver: #c4c4c4;
  --red: #f00;
  --neutral-50: #f7f7f8;
  --neutral-100: #f1f1f1;
  --neutral-200: #ececec;
  --neutral-300: #cfcfcf;
  --neutral-400: #b6b6b6;
  --neutral-500: #9f9f9f;
  --neutral-700: #737373;
  --green: #34c759;
  --primary-gradient: linear-gradient(239.18deg, #b83af3 9.83%, #6950fb 105.7%);
  --wrapper-width: 1340px;
  --simple-layout-max-width: 1280px;
  --viewport-height: 100%;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 100 500;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 600 700;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 800 900;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif, sans-serif;
  background-color: #fff; //#e5e5e5;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  border: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }

  50% {
    opacity: 1;
    transform: scale(1.05);
  }

  70% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.bounceIn {
  animation: bounceIn 500ms forwards;
}

@keyframes bounceOut {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.95);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    opacity: 0;
    transform: scale(0.3);
  }
}

.bounceOut {
  animation: bounceOut 300ms ease;
}

.shake {
  animation: shake 1s ease;
}

@keyframes shake {
  30% {
    transform: scale(1.2);
  }

  40%,
  60% {
    transform: rotate(-20deg) scale(1.2);
  }

  50% {
    transform: rotate(20deg) scale(1.2);
  }

  70% {
    transform: rotate(0deg) scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes fadeInScale {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.fadeInScale {
  animation-name: fadeInScale;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  transform-origin: 50% 0 0;
}

@keyframes fadeOutScale {
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(0);
    opacity: 0;
  }
}

.fadeOutScale {
  animation-name: fadeOutScale;
  animation-duration: 200ms;
  transform-origin: 50% 0 0;
  animation-fill-mode: forwards;
}

@keyframes slideInFromBottom {
  from {
    bottom: calc(237px * -1);
  }

  to {
    bottom: 0;
  }
}

.slideInFromBottom {
  animation-name: slideInFromBottom;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
}

@keyframes slideOutToBottom {
  from {
    bottom: 0;
  }

  to {
    bottom: calc(237px * -1);
  }
}

.slideOutToRight {
  animation-name: slideOutFromBottom;
  animation-duration: 50ms;
  animation-fill-mode: forwards;
}

.Card {
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  padding: 24px 16px;

  @media (min-width: 576px) {
    padding: 30px;
  }
}

/**
  * Exteing Bootstrap row-cols classes for 8 columns

  */
@media (min-width: 1200px) {
  .row-cols-xl-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

img {
  max-width: 100%;
}

svg {
  max-height: 100%;
  max-width: 100%;
}

.oneLineText {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
}

.twoLinesText {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
}

.threeLinesText {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  // word-break: break-all;
  white-space: normal;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.Scrollbar::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.Scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
.Scrollbar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
.Scrollbar::-webkit-scrollbar-button {
  display: none;
}

/* Style Scrollbar for Custom Scrollbar*/
.thumbX,
.thumbY {
  background: #7741de !important;
}

.trackX,
.trackY {
  background: #ddcafc !important;
}

/*Lock body scroll when modal is Open*/
.lock-scroll {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  padding-right: 15px;
  overflow: hidden;
}
