.TimeAvailability {
  width: 100%;
  &__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-decoration: none;
    border-radius: 12px;
    cursor: pointer;
  }

  &__notAvailableRow {
    color: var(--red);
    text-align: right;
  }

  &__timeAvailable {
    display: flex;
    flex-direction: column;
    gap: 2px;

    &Row {
      text-align: right;
    }
  }

  &__timeRow {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
  }
}
