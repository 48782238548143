.HoverVideoPlayer {
  display: block;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;

  &__loader {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 9;
    width: 16px;
    height: 16px;
  }

  &__pausedOverlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    transition: all 0.3s;
  }

  video {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
