.RichText {
  display: flex;
  flex-direction: column;

  h1,
  h2,
  h3 {
    margin-bottom: 0.8em;
    font-weight: 600;
  }

  h1 {
    font-size: 23px;
    line-height: 23px;
  }

  h2 {
    font-size: 20px;
    line-height: 20px;
  }

  h3 {
    font-size: 17px;
    line-height: 17px;
  }

  p {
    color: #080a11;
    white-space: pre-line;
    word-break: break-word;
    margin-bottom: 1.5em;
  }

  a {
    color: var(--primary);
  }
}
