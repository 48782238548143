.Tooltip {
  $base: &;
  $white: #ffffff;
  $border: 10px solid;

  &--flex {
    #{$base}__container {
      display: flex;
      flex-direction: column;
    }
  }

  &--small {
    width: 20px;
    height: 20px;
  }
  &--ellipsis {
    display: flex;
    max-width: 100%;

    #{$base}__container {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      cursor: default;
      display: inline-block;
    }
  }

  &--ellipsis-multi {
    #{$base}__container {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
    &.Elipsis-2 {
      #{$base}__container {
        -webkit-line-clamp: 2;
      }
    }

    &.Elipsis-3 {
      #{$base}__container {
        -webkit-line-clamp: 3;
      }
    }
  }

  &__container {
    position: relative;
    display: inline-flex;
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  &__content {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #50565b;
    position: absolute;
    z-index: 102;
    padding: 8px;
    font-size: 12px;
    cursor: default;
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.24);
    word-break: break-word;
    opacity: 0;

    &--isShown {
      animation: fadeIn ease-in-out 0.25s forwards;
    }

    &_top {
      bottom: calc(100% + 8px);
      left: 50%;
      transform: translateX(-50%);
    }

    &_right,
    &_left {
      top: 50%;
      transform: translateY(-50%);
    }

    &_right {
      left: calc(100% + 8px);
    }

    &_left {
      right: calc(100% + 8px);
    }

    &_bottom {
      top: calc(100% + 8px);
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__arrow {
    position: absolute;
    width: 0;
    height: 0;

    &_top {
      bottom: -8px;
      left: calc(50% - 10px);
      border-right: $border transparent;
      border-top: $border $white;
      border-left: $border transparent;
    }

    &_right,
    &_left {
      top: calc(50% - 10px);
      border-top: $border transparent;
      border-bottom: $border transparent;
    }

    &_left {
      right: -8px;
      border-left: $border $white;
    }

    &_right {
      left: -8px;
      border-right: $border $white;
    }

    &_bottom {
      top: -8px;
      left: calc(50% - 10px);
      border-right: $border transparent;
      border-bottom: $border $white;
      border-left: $border transparent;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
