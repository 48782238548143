.MasterFilter {
  display: flex;
  position: relative;
  align-items: center;
  background: var(--white);
  width: 100%;
  height: 52px;
  padding: 0 5px;
  border-radius: 12px;
  border: 1px solid rgba(119, 65, 222, 1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  gap: 12px;

  &__search {
    flex: 1;
  }

  &__hyperLocal {
    flex: 1;
  }

  @media (min-width: 700px) {
    &__search {
      flex: 4;
    }

    &__hyperLocal {
      flex: 2;
    }
  }

  &__separator {
    display: block;
    width: 0;
    height: 30px;
    border-left: 0.7px solid rgba(159, 159, 159, 1);
  }

  &__controlButton {
    background: transparent !important;
    border-radius: 12px;
    height: 42px !important;
    line-height: 42px !important;
    width: 42px !important;
    border: 1px solid rgba(119, 65, 222, 1) !important;

    &--active {
      background: var(--grey-200) !important;
    }
  }

  &__controlIcon {
    &:hover {
      path {
        stroke: var(--primary) !important;
      }
    }

    &--active {
      fill: var(--primary) !important;

      path {
        stroke: var(--primary) !important;
      }
    }
  }

  &__filters {
    display: block;
    position: absolute;
    width: 100%;
    max-width: 600px;
    top: 55px;
    right: 0;
    border-radius: 6px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    z-index: 5;
  }
}
