.ShowMore {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;

  &__content {
    overflow: hidden;
  }

  button {
    border: none;
    background-color: transparent;
  }
}
