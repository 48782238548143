.VideoCard {
  height: 236px;
  filter: drop-shadow(1px 1px 6px rgba(186, 180, 197, 0.35));

  &__type {
    &--profile {
      @media (min-width: 1100px) {
        width: 205px !important;
        height: 320px !important;
      }
    }

    &--compact {
      width: 109px !important;
      height: 190px !important;
    }
  }

  &__container {
    position: relative;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  &__user {
    position: relative;
  }

  &__views {
    position: absolute;
    bottom: 12px;
    right: 12px;
    z-index: 9;
  }

  &__hoverVideo,
  &__thumbnail,
  &__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
    z-index: 0;
    overflow: hidden;
  }

  &__placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }
}
