.ProBadge {
  display: block;
  width: 29px;
  height: 14px;
  background: var(--secondary);
  font-style: italic;
  font-weight: 800;
  font-size: 9px;
  text-align: center;
  line-height: 14px;
  color: #ffffff;
  border-radius: 100px;
}
