.CommentForm {
  $base: &;

  &__container {
    display: flex;
    margin-bottom: 5px;
  }

  &__control {
    min-height: 35px;
  }

  &__loader {
    height: 35px;
  }

  &__helpText {
    font-size: 12px;
    color: var(--grey-400);
  }

  .mentions {
    flex: 1;
  }

  .mentions--multiLine .mentions__control {
    font-size: 14px;
  }
  .mentions--multiLine .mentions__highlighter {
    border: 1px solid transparent;
    padding: 9px;
    min-height: 38px;
  }
  .mentions--multiLine .mentions__input {
    background-color: var(--grey-100) !important;
    border: 1px solid transparent;
    border-radius: 7px;
    padding: 9px;
    outline: 0;
  }

  .mentions__suggestions__list {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 10pt;
  }

  .mentions__suggestions__item {
    padding: 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  .mentions__suggestions__item--focused {
    background-color: #f1e2ff;
  }

  .mentions__mention {
    position: relative;
    z-index: 1;
    color: var(--primary);
    text-shadow: 1px 1px 1px var(--grey-100), 1px -1px 1px var(--grey-100),
      -1px 1px 1px var(--grey-100), -1px -1px 1px var(--grey-100);
    pointer-events: none;
  }
}
