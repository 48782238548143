.FollowButton {
  $base: &;
  height: 30px !important;
  min-height: 30px !important;
  line-height: 30px !important;
  font-size: 14px !important;

  &--md {
    height: 38px !important;
    min-height: 38px !important;
    line-height: 38px !important;
    font-size: 17px !important;
  }

  &--large {
    height: 56px !important;
    min-height: 56px !important;
    line-height: 26px !important;
    font-size: 20px !important;
    padding-left: 72px;
    padding-right: 72px;

    @media (max-width: 385px) {
      padding-left: 36px;
      padding-right: 36px;
    }
  }

  &--square {
    border-radius: 9px !important;
  }

  &--light {
    color: var(--primary) !important;
    background-color: white !important;

    path {
      stroke: var(--primary) !important;
    }
  }

  &--light-following {
    color: var(--white) !important;
    border: 1px solid var(--white) !important;
  }
}

.FollowButton__content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
