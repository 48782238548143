.CreatorBadge {
  display: inline-flex;
  width: auto;
  max-width: 86%;
  align-items: center;
  border-radius: 16.5848px;
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
  color: #011f01;
  text-decoration: none;
  top: 7px;
  left: 7px;
  gap: 2px;
  z-index: 9;

  &:hover {
    color: #011f01;
  }

  &__image,
  span {
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin-right: 5px;
    border-radius: 50px;
    border: 1px solid var(--white);
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 15px;
    line-height: 12px;
    color: var(--white);
    background-color: var(--primary);
    text-transform: uppercase;
  }

  &__image {
    object-fit: cover;
  }

  &__name {
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;
    color: var(--white);
  }
}
