.VideoPlayer {
  $base: &;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__background {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    filter: blur(8px);
    -webkit-filter: blur(6px);
    transform: scale(1.1);
    z-index: 6000;
    opacity: 0.4;
  }

  &__touchToPlay,
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__touchToPlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 89;
    cursor: pointer;
  }

  &__watchVideo {
    margin-top: 15px;
    color: #ffffff;

    &:hover {
      color: var(--red);
    }
  }

  &__playIcon {
    width: 50px;
    height: 50px;
    fill: #fff;
    box-shadow: 1px 1px 5px rgba(150, 150, 150, 0.5);
    border-radius: 50px;
  }

  &__wrapper {
    position: relative;

    @media (min-width: 992px) {
      border-radius: 12px;
      overflow: hidden;
    }
  }

  .react-player {
    flex: 1;
    height: calc(var(--viewport-height) - 55px) !important;
    z-index: 7000;

    @media (min-width: 800px) {
      height: calc(var(--viewport-height) - 80px) !important;
    }

    @media (min-width: 992px) {
      //height: calc(var(--viewport-height) - 108px) !important;
    }
  }

  video {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    background-size: contain;
  }
}
