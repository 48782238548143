.Steps {
  margin: 0 -9px;

  &__item {
    margin: 0 9px;
    width: 100%;
  }

  &__figure {
    height: 8px;
    background: var(--grey-100);
    border-radius: 8px;
    width: 100%;
    background: #ddcafc;

    &--passed {
      background: var(--primary);
    }
  }
}
