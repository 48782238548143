.PhoneNumberForm {
  &__errorMessage {
    text-align: center;
    color: var(--red);
    margin-bottom: 12px;
  }

  &__inputs {
    margin-bottom: 17px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__createAccount {
    margin-top: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }

  &__signUp {
    text-decoration: underline !important;
  }
}
