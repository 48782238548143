.SmsCodeForm {
  &__description {
    margin-bottom: 20px;
    font-size: 15px;
    span {
      color: var(--neutral-500);
      font-weight: 600;
    }
  }

  &__errorMessage {
    text-align: center;
    color: var(--red);
    margin-bottom: 12px;
  }

  &__inputs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 17px;
  }

  &__phoneNumberInput {
    display: flex !important;
    flex: 1;
    justify-content: center;
    align-items: center;
    gap: 12px;

    input {
      border-radius: 6px;
      border: 1px solid lightgrey;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;
      margin: 4px;
      padding-left: 8px;
      padding-right: 0px;
      width: 36px;
      height: 42px;
      font-size: 32px;
      box-sizing: border-box;
      color: black;
      background-color: white;

      &:focus-visible {
        outline-color: var(--primary);
      }
    }
  }

  &__resendCode {
    display: flex;
    color: var(--neutral-700);
    flex-direction: column;
    align-items: stretch;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    margin: 22px 10px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__createAccount {
    margin-top: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }

  &__signUp,
  &__resendButton {
    text-decoration: underline !important;
  }
}
