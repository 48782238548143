.VideoDetails {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 17px 30px;

  &__header {
    padding: 15px 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    color: #7741de;
  }

  &__content {
    display: flex;
    justify-content: center;
    gap: 12px;
    flex-wrap: wrap;

    & > div {
      flex: 1 1 48%;
      box-sizing: border-box;
    }
  }
}
