.Message {
  $base: &;
  display: flex;
  margin-top: 15px;
  gap: 7px;

  &__bubble {
    position: relative;
    padding: 12px;
    background: var(--grey-300);
    order: 2;
    max-width: 250px;
    flex: 1;
    border-radius: 0px 50px 50px 50px;
  }

  &__content {
    font-size: 14px;
    font-weight: 400;
    line-height: 14.3px;
    letter-spacing: 0.15px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  &__time {
    font-size: 12px;
    line-height: 12px;
    color: #666666;
    text-align: center;
    padding: 5px 0;
    order: 3;
  }

  &__userImage {
    order: 1;
    flex-shrink: 0;
  }

  &--own {
    justify-content: flex-end;

    #{$base}__bubble {
      color: #ffffff !important;
      background: linear-gradient(-45deg, #6950fb 9.85%, #b83af3 105.94%);
      border-radius: 50px 0px 50px 50px;
    }

    #{$base}__userImage {
      order: 3;
    }

    #{$base}__time {
      order: 1;
    }
  }
}
