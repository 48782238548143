.Search {
  margin-bottom: 80px;

  &__inputWrapper,
  &__tabs {
    width: 100%;
    max-width: 1040px;
    margin: auto;
  }

  &__inputWrapper {
    padding: 20px 15px;

    @media (min-width: 1070px) {
      padding: 20px 0;
    }
  }

  &__tabs {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    @media (max-width: 800px) {
      justify-content: space-between !important;
    }
  }

  &__tab {
    position: relative;
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    color: #333;
    margin-left: 0.2em;
    flex: 1;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 4px;
      bottom: 0;
      left: 0;
      border-radius: 15px;
    }

    &:first-child {
      margin-left: 0;
    }

    @media (min-width: 800px) {
      font-size: 22px;
      font-weight: bold;
      flex-grow: 0;

      &:hover {
        color: #000;

        &::after {
          background: var(--primary-gradient);
        }
      }
    }

    &--active {
      color: #000;

      &::after {
        background: var(--primary-gradient);
      }
    }

    &Badge {
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      line-height: 6px;
      border-radius: 4px;
      background-color: var(--red);
      top: 30%;
      right: 0;
    }
  }

  &__container {
    max-width: 1280px;
    margin: 30px auto 0;

    @media (min-width: 1000px) {
      padding-left: 80px !important;
      padding-right: 80px !important;
    }
  }

  &__content {
    width: 100%;
    max-width: 1040px;
    margin: 0 auto;
  }

  &__hashtagTitle {
    color: var(--primary);
  }
}
