.RecurrentDateSelector {
  $base: &;
  position: relative;
  padding: 8px 0;
  width: 100%;
  // overflow: hidden;

  &__list {
    display: flex;
    position: absolute;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__prevButton,
  &__nextButton {
    display: block;
    position: absolute;
    width: 25px;
    height: 25px;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    cursor: pointer;
    background-color: transparent;

    svg {
      min-width: 25px;
      width: 25px;
    }

    &--disable {
      display: none;
    }
  }

  &__prevButton {
    left: 0px;
  }

  &__nextButton {
    right: 0px;
  }

  &__item {
    display: inline-flex;
    position: relative;
    flex: 0 0 145px;
    min-width: 13px;
    min-height: fit-content;
    padding: 14px 25px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    border: 1px solid transparent;
    background: var(--neutral-50);
    cursor: default;

    &--clickable {
      cursor: pointer;
    }

    &--selected {
      border: 1px solid var(--primary);
      background: var(--primary-lighter);
    }

    &--disabled {
      #{$base}__date {
        background: transparent;
        background-clip: initial;
        -webkit-background-clip: initial;
        -webkit-text-fill-color: initial;
      }

      #{$base}__date,
      #{$base}__monthYear,
      #{$base}__day,
      #{$base}__time {
        color: var(--neutral-500);
      }
    }
  }

  &__date {
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
    background: var(--primary-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__monthYear {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  &__day {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 12px;
    color: var(--neutral-500);
  }

  &__time {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.28px;
  }

  &__soldOut,
  &__closed {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }

  &__closed {
    color: var(--neutral-500);
  }

  &__badge {
    display: inline-flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding: 2px 6px;
    margin-bottom: -20px;
    gap: 2px;
    top: -20px;
    left: 50%;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    background: var(--red);
    box-shadow: 1px 1px 6px 0px rgba(186, 180, 197, 0.35);
    border-radius: 4px;
    transform: translateX(-50%);

    svg {
      max-width: 12px;
      width: 12px;

      path {
        fill: #fff !important;
      }
    }
  }
}
