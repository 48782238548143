.Modal {
  $base: &;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1200;
  display: flex;
  align-items: center;
  justify-content: center;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(61, 63, 124, 0.6),
      rgba(61, 63, 124, 0.6)
    );
    z-index: 98;
  }

  &--normal-screen {
    #{$base}__container {
      border-radius: 14px;
      width: 100%;
      margin: 0 7px;
      max-width: 600px;
      max-height: calc(100% - 24px);

      @media (min-width: 500px) {
        max-height: auto !important;
        margin: 0;
      }
    }

    #{$base}__inner {
      width: 100%;
    }
  }

  &--full-screen {
    align-items: flex-start;
    top: 55px;
    z-index: 1000 !important;
    height: calc(var(--viewport-height) - 55px);
    max-height: calc(var(--viewport-height) - 55px);

    @media (min-width: 800px) {
      top: 80px;
      height: calc(var(--viewport-height) - 80px) !important;
      max-height: calc(var(--viewport-height) - 80px);
    }

    #{$base}__container {
      width: 100vw;
      height: 100%;
      max-height: 100%;

      // @media (min-width: 800px) {
      //   min-height: calc(100% - 80px);
      //   max-height: calc(100% - 80px);
      // }
    }

    #{$base}__inner {
      width: 100%;
      max-width: 100%;
    }
  }

  &__container {
    background: #fff;
    z-index: 99;
    overflow: hidden;
  }

  &__inner {
    display: flex;
    height: 100%;
  }

  &__body {
    flex: 1;
    width: 100%;
  }

  &__close {
    display: flex;
    align-items: center !important;
    position: absolute;
    z-index: 99;
    top: 20px;
    left: 20px;
    z-index: 9999;
    height: 42px !important;
    min-height: 42px !important;

    @media (min-width: 800px) {
      top: 40px;
      left: 40px;
    }
  }

  &__crossIcon {
    position: relative;
    top: -1px;
    width: 19px;
    min-width: 19px;
    height: 19px;

    .cross {
      fill: #fff;
    }

    @media (min-width: 800px) {
      .cross {
        fill: #ffffff !important;
      }
    }
  }
}
