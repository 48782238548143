.ExperienceDescription {
  padding: 12px 0;
  &__authorDetails {
    display: flex;
    gap: 6px;
    align-items: center;
    margin-bottom: 8px;
    max-width: calc(100% - 60px);

    @media (min-width: 950px) {
      max-width: calc(100% - 115px);
    }
  }

  &__authorUsername {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: var(--white);
  }

  &__authorCompany {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-top: 2px;
    color: var(--white);
  }

  &__container {
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    color: var(--white);
    max-width: calc(100% - 35px);

    @media (min-width: 950px) {
      max-width: calc(100% - 115px);
    }
  }

  &__name {
    font-weight: 600;
    color: var(--white);
  }

  &__price {
    font-weight: 600;
    font-size: 17px;
    color: var(--white);
    margin-top: 7px;
  }

  &__viewExperience {
    display: flex;
    align-items: center;
    justify-content: center;

    .Button {
      padding: 0 50px !important;
    }
  }
}
