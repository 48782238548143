.modal {
  padding: 20px;
  &__title {
    color: #303273;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 15px;
  }

  &__text {
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #787c8e;
  }

  &__emoji {
    font-size: 40px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0 24px;
  }
}
