.CustomPhoneInput {
  position: relative;
  padding-bottom: 18px;

  &__inputWrapper {
    position: relative;
  }

  input {
    background-color: transparent !important;
    box-sizing: border-box;
    padding: 0 14px;
    line-height: 100%;
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 40px;
    color: #000;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;

    @media (min-width: 800px) {
      height: 46px;
      line-height: 46px;
    }

    &::placeholder {
      color: var(--dark);
    }

    &:focus-visible {
      outline: none;
    }
  }

  &__helperText {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 12px;
    color: #555;
  }

  &__error {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 12px;
    color: var(--red);
  }

  &__label {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: var(--primary);
  }
}

.PhoneInput {
  background-color: var(--grey-300) !important;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 0px 14px;
  height: 40px;
  border: 1px solid var(--primary-light) !important;

  @media (min-width: 800px) {
    height: 46px;
  }
}
