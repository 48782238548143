.VideoGallery {
  width: 100%;
  &__noClips {
    color: #7d7d7f;
    border-radius: 12px;
  }

  &--profile {
    @media (min-width: 1300px) {
      gap: 12px;
    }
  }
}
