.CommentsModal {
  display: flex;
  flex-direction: column;

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #303273;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 7px;
    padding: 20px 20px 12px;
    border-bottom: 1px solid var(--grey-200);
  }

  &__close {
    width: 28px !important;
    height: 28px !important;
    min-width: 28px !important;
    min-height: 28px !important;
    line-height: 28px !important;
  }

  &__closeIcon {
    width: 15px;
    height: 15px;
  }

  &__content {
    display: flex;
    border-top: 1px solid var(--grey--200);
    border-bottom: 1px solid var(--grey--200);
    max-height: calc(80vh - 180px);
  }

  &__commentList {
    flex: 1;
    height: 100%;
    padding: 20px;
  }

  &__no-comments {
    display: block;
    text-align: center;
    color: #7d7d7f;
  }

  &__actions {
    width: 100%;
  }

  &__commentForm {
    border-top: 1px solid var(--grey-200);
    padding: 18px;
  }
}
