.Section {
  width: 100%;
  padding: 20px 0;

  &__header {
    display: flex;
    align-items: center;
    color: #000000;
    margin-bottom: 12px;
    gap: 7px;

    @media (min-width: 768px) {
      gap: 12px;
    }

    &--bottom-line {
      border-bottom: 1px solid var(--grey-300);
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
  }

  &__title {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    margin: 0;
    color: var(--primary);
    text-transform: capitalize;

    @media (min-width: 768px) {
      font-size: 26px;
    }

    &--spaced {
      margin-left: 7px;
    }
  }

  &__information {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    margin-left: 8px;
    color: var(--neutral-500);
  }

  &__action {
    margin-left: auto;
    background-color: transparent;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    color: rgba(184, 58, 243, 1);

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
}
