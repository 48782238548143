.CreatorCard {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 12px 16px;
  background: linear-gradient(239.18deg, #b83af3 9.83%, #6950fb 105.7%);
  border-radius: 12px;
  color: var(--white);

  @media (max-width: 820px) {
    flex-direction: column;
    align-items: stretch;
  }

  &__personalInfo {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__name {
    font-size: 16px;
    line-height: 10px;
    margin-right: 9px;

    a {
      color: var(--white);
    }
  }

  &__username {
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    word-break: break-all;

    a {
      color: var(--white);
      word-break: break-all;
      text-decoration: none;
    }
  }

  &__details {
    margin-top: 7px;
  }

  &__followButton {
    margin-left: auto;
    align-self: center;
  }

  @media (max-width: 820px) {
    &__followButton {
      width: 50% !important;
      margin: 0;
    }
  }
}
