.ExperienceModal {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;

  &__closeButton {
    background: rgba(223, 211, 255, 0.5);
    backdrop-filter: blur(5px);
    color: rgb(48, 47, 47);

    .cross {
      fill: rgb(48, 47, 47) !important;
    }

    &--hidden {
      display: none !important;
    }
  }

  @media (min-width: 800px) {
    display: flex;
  }

  &__content {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__mediaControls {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    gap: 12px;
    top: 75px;
    right: 20px;
    z-index: 9999;

    @media (min-width: 950px) {
      right: 50px;
      top: 100px;
    }
  }

  &__actions {
    position: absolute;
    bottom: 40px;
    right: 20px;
    z-index: 9999;

    @media (min-width: 950px) {
      right: 50px;
      bottom: 60px;
    }
  }

  &__actionButton {
    position: relative;
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
    background: rgba(223, 211, 255, 0.5);
    backdrop-filter: blur(5px);
    color: rgb(48, 47, 47);
    border-radius: 500px;

    svg {
      width: 12px;
      height: auto;
    }

    &--up {
      transform: rotate(-180deg);
    }

    @media (min-width: 800px) {
      width: 50px !important;
      height: 50px !important;
      min-width: 50px !important;
      min-height: 50px !important;

      svg {
        width: 18px;
        height: auto;
      }
    }
  }

  &__upButton {
    margin-bottom: 5px;

    @media (min-width: 800px) {
      margin-bottom: 25px;
    }
  }

  &__experienceDescription {
    display: none;

    @media (max-width: 800px) {
      display: block !important;
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &--overlap {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9999;
      background-color: #fff;
    }

    @media (min-width: 800px) {
      min-width: 411px;
      max-width: 411px;
    }

    @media (min-width: 1100px) {
      position: static;
      min-width: 548px;
      max-width: 548px;
    }
  }

  &__content-wrapper {
    display: flex;
    width: 100%;
    max-height: 100%;
    background-color: #ffffff;

    & > div > div {
      margin: 0;
    }
  }
}
