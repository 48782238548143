.ReportMediaModal {
  display: flex;
  flex-direction: column;

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #303273;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    padding: 20px 20px 12px;
    border-bottom: 1px solid var(--grey-200);
  }

  &__close {
    width: 28px !important;
    height: 28px !important;
    min-width: 28px !important;
    min-height: 28px !important;
    line-height: 28px !important;
  }

  &__closeIcon {
    width: 15px;
    height: 15px;
  }

  &__content {
    max-height: calc(90vh - 180px);
  }

  &__description {
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #737373;
    padding: 24px;
  }

  &__reasons {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: stretch;
    padding: 0 24px;
  }

  &__reasonButton {
    display: block;
    background: #f7f7f8;
    border-radius: 8px;
    border: none;
    font-weight: 500;
    font-size: 14px;
    height: 45px;
    line-height: 45px;
    letter-spacing: 0.02em;
    color: #000000;

    &--selected {
      background: linear-gradient(239.18deg, #b83af3 9.83%, #6950fb 105.7%);
      color: var(--white);
    }
  }

  &__actions {
    width: 100%;
    padding: 24px;
  }
}
