.VideoDescription {
  padding: 12px 0;
  &__authorDetails {
    display: flex;
    gap: 6px;
    align-items: center;
    margin-bottom: 8px;
    max-width: calc(100% - 60px);

    @media (min-width: 950px) {
      max-width: calc(100% - 115px);
    }
  }

  &__authorUsername {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: var(--white);
    word-break: break-word;
  }

  &__authorCompany {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-top: 2px;
    color: var(--white);
    word-break: break-word;
  }

  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: var(--white);
    max-width: calc(100% - 60px);
    word-break: break-word;

    span {
      width: 100% !important;
      max-width: 100% !important;
    }

    @media (min-width: 950px) {
      max-width: calc(100% - 115px);
    }
  }

  &__showMore {
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    text-decoration-line: underline;
    color: var(--white);
    cursor: pointer;
  }

  &__viewExperience {
    &Button {
      display: flex;
      align-items: center;
      background: transparent;
      border: 1px solid var(--primary-light) !important;
      backdrop-filter: blur(5px);
      border-radius: 12px;
      color: var(--primary-light) !important;
      font-size: 16px !important;
      margin: 14px 0;
      padding: 0 10px !important;
      line-height: 35px !important;
      height: 35px !important;
      gap: 12px;

      &:hover {
        border-color: darken(#cab0fc, 50%);
        background-color: transparent !important;
      }
    }
  }
}
