.UserLocationModal {
  &__header {
    position: relative;
    padding: 30px 0 20px;
  }

  &__logo {
    text-align: center;
    margin-bottom: 12px;
  }

  &__title {
    flex: 1;
    color: #000;
    font-weight: 600;
    font-size: 24px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 5px;
  }

  &__description {
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    margin: 12px 0;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 28px !important;
    height: 28px !important;
    min-width: 28px !important;
    min-height: 28px !important;
    line-height: 28px !important;
  }

  &__closeIcon {
    width: 15px;
    height: 15px;
  }

  &__actions {
    display: flex;
    justify-content: center;
    gap: 12px;
    padding: 17px;

    @media (max-width: 450px) {
      flex-direction: column;
    }
  }

  &__grantPermissions {
    @media (max-width: 450px) {
      order: 1;
    }
  }

  &__closePermissions {
    @media (max-width: 450px) {
      order: 2;
    }
  }
}
