.AuthModal {
  &__header {
    position: relative;
    padding: 30px 0 20px;
  }

  &__logo {
    text-align: center;
    margin-bottom: 12px;
  }

  &__title {
    flex: 1;
    color: #000;
    font-weight: 600;
    font-size: 28px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 5px;
  }

  &__description {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    margin: 12px 0 24px;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 28px !important;
    height: 28px !important;
    min-width: 28px !important;
    min-height: 28px !important;
    line-height: 28px !important;
  }

  &__closeIcon {
    width: 15px;
    height: 15px;
  }

  &__content {
    padding: 0 10px 10px;

    @media (min-width: 390px) {
      padding: 0 20px 10px;
    }

    @media (min-width: 500px) {
      padding: 0 50px 10px;
    }
  }

  &__storeButtons {
    display: flex;
    justify-content: center;
    border-top: 1px solid var(--grey-100);
    padding: 10px 0;
  }
}
