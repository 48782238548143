.SearchInput {
  $base: &;
  display: flex;
  gap: 7px;

  &__inputWrapper {
    display: flex;
    position: relative;
    align-items: center;
    height: 50px;
    padding: 0 12px;
    line-height: 50px;
    background: transparent;
    flex: 1;
  }

  &__searchIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    left: 15px;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    color: var(--primary);
  }

  &__input {
    width: 100%;
    border: none;
    background-color: transparent;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;

    &::placeholder {
      color: rgba(60, 60, 60, 1);
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
    }

    &:focus {
      outline: none;
    }
  }
}
