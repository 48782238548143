.SignInForm {
  &__errorMessage {
    text-align: center;
    color: var(--red);
    margin-bottom: 12px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__forgotPassword {
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;

    &Button {
      align-self: flex-end;
      font-size: 14px !important;
      line-height: 18px !important;
      height: 18px !important;
    }
  }

  &__createAccount {
    margin-top: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }

  &__signUp {
    text-decoration: underline !important;
  }
}
